import { Activity, FlatInteractionModelData } from 'types/shared'

export const flatValuesToOldActivities = (
  flatValues: FlatInteractionModelData['activity_values'],
  groupIndex?: number
): Activity[] => {
  return Object.entries(flatValues).flatMap(([uuid, value]) => {
    const uuid_activity_model =
      groupIndex === undefined ? uuid : `${uuid}-${groupIndex}`

    if (Array.isArray(value)) {
      return {
        uuid_activity_model,
        des: JSON.stringify(
          value.map((group, valueGroupIndex) =>
            flatValuesToOldActivities(group, valueGroupIndex)
          )
        )
      }
    }

    if (typeof value === 'string') {
      return { uuid_activity_model, des: value }
    }

    return { uuid_activity_model, des: value.id, complement: value.des }
  })
}
