import { FocusEventHandler, useCallback, useState } from 'react'

import ActivityInputAgreementTerm from './ActivityInputAgreementTerm'
import ActivityInputDate from './ActivityInputDate'
import ActivityInputFileS3 from './ActivityInputFileS3'
import ActivityInputInteger from './ActivityInputInteger'
import ActivityInputSelect from './ActivityInputSelect'
import ActivityInputString from './ActivityInputString'
import ActivityInputTakePhoto from './ActivityInputTakePhoto'
import ActivityInputTakeVideo from './ActivityInputTakeVideo'

import {
  ActivityModel,
  ActivityValue,
  FlatActivityModel,
  StatusClass,
  ValidationStatus
} from 'types/shared'
import '../../../../asset/css/form.css'
import ActivityInputDraw from './ActivityInputDraw/ActivityInputDraw'
import { VALIDATION_STATUSES } from '../../../../utils/constants'
import useBlueprintsContext from '../../hooks/useBlueprintsContext'
import SiteRenderer from '../SiteRenderer'
import ActivityMultiselectionAccordion from './AcitivityExplicitMultiselectionAccordion/ActivityMultiselectionAccordion'
import ActivityExplicitUniselectionAccordion from './AcitivityExplicitUniselectionAccordion/ActivityUniselectionAccordion'
import { ActivityInputProps } from './Activity.types'
import ActivityAutocompleteMultiselection from './ActivityAutocompleteMultiselection/ActivityAutocompleteMultiselection'
import ActivityAutocompleteUniselection from './ActivityAutocompleteUniselection/ActivityAutocompleteUniselection'
import ActivityDocumentFile from './ActivityDocumentFile/ActivityDocumentFile'
import ActivityDocumentPhoto from './ActivityDocumentPhoto'
import ActivityExplicitMultiselection from './ActivityExplicitMultiselection/ActivityExplicitMultiselection'
import ActivityExplicitUniselection from './ActivityExplicitUniselection/ActivityExplicitUniselection'
import ActivityGroup from './ActivityGroup'
import ActivityInputArea from './ActivityInputArea'
import ActivityInputGeolocation from './ActivityInputGeolocation'
import ActivityOTP from './ActivityOTP/ActivityOTP'
import ActivityStandardMultiselection, {
  ActivityStandardMultiselectionProps
} from './ActivityStandardMultiselection/ActivityStandardMultiselection'
import ActivityStandardUniselection, {
  ActivityStandardUniselectionProps
} from './ActivityStandardUniselection/ActivityStandardUniselection'
import ActivityYta from './ActivityYta'
import ActivitySafeHTML from './ActivitySafeHTML/ActivitySafeHTML'
import useValidateActivity from 'modules/interaction/hooks/useValidateActivity/useValidateActivity'

const ActivityInput = ({
  activityModelInput,
  value,
  parentPath = [],
  inputClass,
  statusClass,
  currentStep,
  isFetchingBlueprints = false,
  handleChange
}: ActivityInputProps) => {
  const stringInputValue = (typeof value === 'object' ? value.des : value) || ''

  if (activityModelInput.content_type === 'safe_html') {
    return <ActivitySafeHTML htmlContent={activityModelInput.html_content} />
  }

  if (activityModelInput.content_type === 'integer') {
    return (
      <ActivityInputInteger
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        value={stringInputValue}
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        formatMask={activityModelInput.format_mask}
      />
    )
  }

  if (activityModelInput.content_type === 'date') {
    return (
      <ActivityInputDate
        id={activityModelInput.uuid}
        statusClass={statusClass}
        value={value}
        format='date'
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        {...{ isFetchingBlueprints }}
        minimumYear={activityModelInput.minimum_year}
        maximumYear={activityModelInput.maximum_year}
      />
    )
  }

  if (activityModelInput.content_type === 'datetime') {
    return (
      <ActivityInputDate
        id={activityModelInput.uuid}
        statusClass={statusClass}
        cssClass={inputClass}
        value={value}
        format='date-time'
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        {...{ isFetchingBlueprints }}
        minimumYear={activityModelInput.minimum_year}
        maximumYear={activityModelInput.maximum_year}
      />
    )
  }

  /**
   * @deprecated
   */
  if (activityModelInput.content_type === 'file') {
    return (
      <ActivityInputFileS3
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        inputValue={stringInputValue}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text?.des}
        readOnly={activityModelInput.informational}
      />
    )
  }

  /**
   * @deprecated
   */
  if (activityModelInput.content_type === 'take_photo') {
    return (
      <ActivityInputTakePhoto
        inputValue={stringInputValue}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
      />
    )
  }

  /**
   * Non-standard component
   */
  if (activityModelInput.content_type === 'take_video') {
    return (
      <ActivityInputTakeVideo
        inputValue={stringInputValue}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'agreement_term') {
    return (
      <ActivityInputAgreementTerm
        onChange={handleChange}
        inputValue={stringInputValue}
        terms={activityModelInput.display_text.des}
      />
    )
  }

  if (activityModelInput.content_type === 'draw') {
    return (
      <ActivityInputDraw
        inputValue={stringInputValue}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        explanation={activityModelInput.explanation_text?.des}
        agreement={activityModelInput.agreement}
        readOnly={activityModelInput.informational}
      />
    )
  }

  if (activityModelInput.content_type === 'activity_group') {
    return (
      <ActivityGroup
        currentActivityModel={activityModelInput}
        parentPath={parentPath}
        onChange={handleChange}
        label={activityModelInput.display_text.des}
        currentStep={currentStep}
      />
    )
  }

  if (activityModelInput.content_type === 'document_photo') {
    return (
      <ActivityDocumentPhoto
        inputValue={stringInputValue}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        required={activityModelInput.required}
      />
    )
  }

  if (activityModelInput.content_type === 'document_file') {
    return (
      <ActivityDocumentFile
        inputValue={value || ''}
        onChange={handleChange}
        label={activityModelInput.display_text?.des}
        required={activityModelInput.required}
        actions={activityModelInput.actions}
        maxSizeInBytes={activityModelInput.max_size_in_bytes}
        accept={activityModelInput.accept}
        explanationText={activityModelInput.explanation_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'textarea') {
    return (
      <ActivityInputArea
        id={activityModelInput.uuid}
        cssClass={`${inputClass}`}
        value={stringInputValue}
        required={activityModelInput.required}
        onChange={handleChange}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'explicit_uniselection_objectclass') {
    return (
      <ActivityExplicitUniselection
        id={activityModelInput.uuid}
        value={value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        label={activityModelInput.display_text.des}
        direction={activityModelInput.direction || 'vertical'}
        optionLabelsPosition={
          activityModelInput.optionLabelsPosition || 'right'
        }
        required={activityModelInput.required}
        onChange={handleChange}
      />
    )
  }

  if (
    activityModelInput.content_type === 'explicit_multiselection_objectclass'
  ) {
    return (
      <ActivityExplicitMultiselection
        id={activityModelInput.uuid}
        value={value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        label={activityModelInput.display_text.des}
        direction={activityModelInput.direction || 'vertical'}
        optionLabelsPosition={
          activityModelInput.optionLabelsPosition || 'right'
        }
        required={activityModelInput.required}
        onChange={handleChange}
      />
    )
  }

  if (activityModelInput.content_type === 'geolocation') {
    return (
      <ActivityInputGeolocation
        id={activityModelInput.uuid}
        label={activityModelInput.display_text.des}
        value={stringInputValue}
        required={activityModelInput.required}
        dependencies={activityModelInput.geolocation_dependencies}
        onChange={handleChange}
        parentPath={parentPath}
        fillGeolocationValues={activityModelInput.fill_geolocation_values}
      />
    )
  }

  /* Não está validado com ActivityGroup */
  if (activityModelInput.content_type === 'yta') {
    return (
      <ActivityYta
        sourceDataContainerVariable={
          activityModelInput.yta?.source_data_container_variable
        }
      />
    )
  }

  if (activityModelInput.content_type === 'autocomplete_multiselection') {
    return (
      <ActivityAutocompleteMultiselection
        id={activityModelInput.uuid}
        statusClass={statusClass}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        endpoint={activityModelInput.information_request_callback}
        value={value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text.des}
        informational={activityModelInput.informational}
        placeholder={activityModelInput.explanation_text?.des}
        actions={activityModelInput.actions}
        {...{ isFetchingBlueprints }}
      />
    )
  }

  if (activityModelInput.content_type === 'autocomplete_uniselection') {
    return (
      <ActivityAutocompleteUniselection
        id={activityModelInput.uuid}
        statusClass={statusClass}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        endpoint={activityModelInput.information_request_callback}
        value={value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text.des}
        informational={activityModelInput.informational}
        placeholder={activityModelInput.explanation_text?.des}
        actions={activityModelInput.actions}
        {...{ isFetchingBlueprints }}
      />
    )
  }

  if (activityModelInput.content_type === 'standard_uniselection') {
    return (
      <ActivityStandardUniselection
        id={activityModelInput.uuid}
        value={value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        label={activityModelInput.display_text.des}
        required={activityModelInput.required}
        onChange={handleChange}
        statusClass={
          statusClass as ActivityStandardUniselectionProps['statusClass']
        }
        placeholder={activityModelInput?.explanation_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'standard_multiselection') {
    return (
      <ActivityStandardMultiselection
        id={activityModelInput.uuid}
        value={value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        label={activityModelInput.display_text.des}
        required={activityModelInput.required}
        onChange={handleChange}
        statusClass={
          statusClass as ActivityStandardMultiselectionProps['statusClass']
        }
        placeholder={activityModelInput?.explanation_text?.des}
      />
    )
  }

  if (activityModelInput.content_type === 'one_time_password') {
    return (
      <ActivityOTP
        id={activityModelInput.uuid}
        value={value}
        label={activityModelInput.display_text.des}
        length={activityModelInput.length}
        required={activityModelInput.required}
        onChange={handleChange}
        statusClass={statusClass as ActivityOTP['statusClass']}
      />
    )
  }

  if (activityModelInput.content_type === 'explicit_uniselection_accordion') {
    return (
      <ActivityExplicitUniselectionAccordion
        id={activityModelInput.uuid}
        value={value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        hideAccordionArrowOnDisable={
          activityModelInput.hideAccordionArrowOnDisable
        }
        required={activityModelInput.required}
        onChange={handleChange}
      />
    )
  }

  if (activityModelInput.content_type === 'explicit_multiselection_accordion') {
    return (
      <ActivityMultiselectionAccordion
        id={activityModelInput.uuid}
        value={value || ''}
        options={activityModelInput.selection_options}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        hideAccordionArrowOnDisable={
          activityModelInput.hideAccordionArrowOnDisable
        }
        required={activityModelInput.required}
        onChange={handleChange}
      />
    )
  }

  const isStringAsAutocomplete = Boolean(
    activityModelInput.information_request_callback ||
      activityModelInput.information_request_filteraction
  )

  if (isStringAsAutocomplete) {
    return (
      <ActivityAutocompleteUniselection
        id={activityModelInput.uuid}
        statusClass={statusClass}
        filteraction={activityModelInput.information_request_filteraction}
        filteractionParams={
          activityModelInput.information_request_filteraction_query_params
        }
        endpoint={activityModelInput.information_request_callback}
        value={value || ''}
        onChange={handleChange}
        required={activityModelInput.required}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
      />
    )
  }

  const isStringAsSelect = Boolean(
    activityModelInput.information_request_textexpression
  )

  if (isStringAsSelect) {
    return (
      <ActivityInputSelect
        id={activityModelInput.uuid}
        onChange={handleChange}
        options={activityModelInput.information_request_textexpression}
        inputValue={stringInputValue}
        label={activityModelInput.display_text.des}
        readOnly={activityModelInput?.informational}
        required={activityModelInput.required}
        helpText={activityModelInput.help_text?.des}
        explanationText={activityModelInput.explanation_text?.des}
        statusClass={statusClass}
        {...{ isFetchingBlueprints }}
      />
    )
  }

  return (
    <ActivityInputString
      id={activityModelInput.uuid}
      statusClass={statusClass}
      value={stringInputValue}
      required={activityModelInput?.required}
      onChange={handleChange}
      label={activityModelInput?.display_text?.des}
      readOnly={activityModelInput?.informational}
      helpText={activityModelInput?.help_text?.des}
      explanationText={activityModelInput?.explanation_text?.des}
      formatMask={activityModelInput.format_mask}
      {...{ isFetchingBlueprints }}
    />
  )
}

export type ActivityProps = {
  activityModelInput: FlatActivityModel
  value?: ActivityValue
  parentPath?: string[]
  currentStep: number
  changeActivity: (
    uuid: string,
    value: { id: string; des: string } | string
  ) => void
}

export default function Activity({
  activityModelInput,
  value,
  changeActivity,
  parentPath,
  currentStep
}: ActivityProps) {
  const isActivityGroup = activityModelInput.content_type === 'activity_group'

  const { validationStatus, helperText } = useValidateActivity({
    activityModel: activityModelInput,
    value,
    enabled: !isActivityGroup
  })

  const inputClassByStatus: Record<ValidationStatus, string> = {
    [VALIDATION_STATUSES.VALID]: 'is-valid',
    [VALIDATION_STATUSES.INVALID]: 'is-invalid',
    [VALIDATION_STATUSES.PENDING]: ''
  }

  const inputClass = inputClassByStatus[validationStatus]

  const statusClassByValidation: Record<ValidationStatus, StatusClass> = {
    [VALIDATION_STATUSES.VALID]: 'valid',
    [VALIDATION_STATUSES.INVALID]: 'error',
    [VALIDATION_STATUSES.PENDING]: 'default'
  }

  const statusClass = statusClassByValidation[validationStatus]

  const informationRequestSitemodel =
    activityModelInput.information_request_sitemodel

  const { executeBlueprints, isFetchingBlueprints } = useBlueprintsContext()

  const [previousValue, setPreviousValue] = useState<
    ActivityModel['value'] | false
  >(false)

  const handleBlur: FocusEventHandler = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return
    }
    if (previousValue !== value && !isActivityGroup) {
      executeBlueprints()
    }
  }

  const handleFocus = () => {
    setPreviousValue(value)
  }

  const handleChange = useCallback(
    (newValue: ActivityValue) => {
      changeActivity(activityModelInput.uuid, newValue)
    },
    [activityModelInput.uuid, changeActivity]
  )

  return (
    <div
      onFocus={handleFocus}
      onBlur={handleBlur}
      data-testid='activity'
      className={`form-group ${helperText && 'has-validation'}`}
    >
      {informationRequestSitemodel && (
        <SiteRenderer
          sitemodelUuid={informationRequestSitemodel}
          urlSiteRenderer={window.urlSiteRenderer || ''}
        />
      )}

      <ActivityInput
        {...{
          parentPath,
          activityModelInput,
          value,
          handleChange,
          inputClass,
          currentStep,
          statusClass,
          isFetchingBlueprints
        }}
      />
    </div>
  )
}
