import { datepickerToISOFormat } from '@yes.technology/react-toolkit'
import moment from 'moment'
import {
  Activity,
  ActivityGroup,
  ActivityModel,
  ActivityModelContentType,
  ActivityValue,
  FlatInteractionModelData,
  FlatInteractionModelItemGroup,
  InteractionModel,
  InteractionModelData,
  NestableGroupValue
} from 'types/shared'
import api from './api'
import { AllowedLocales } from './date'
import { deepClone } from './helpers/object'
import pick from 'lodash.pick'
import { flatValuesToOldActivities } from 'modules/interaction/utils/flatValuesToOldActivities'

type GroupValidationCallbackRequestData = {
  uuid_interaction_model_item_group: string
  activity_models: { uuid_activity_model: string; value: ActivityValue }[]
}

type ActivityValuesToGroupValidationFormatProps = {
  stepUuid: string
  activityValues: FlatInteractionModelData['activity_values']
}

const nestedGroupsToValueList = (
  nestedGroups: NestableGroupValue
): { uuid_activity_model: string; value: ActivityValue }[] => {
  return nestedGroups.flatMap((group) =>
    Object.entries(group).flatMap(([uuid_activity_model, value]) => {
      if (Array.isArray(value)) {
        return nestedGroupsToValueList(value)
      }

      return { uuid_activity_model, value }
    })
  )
}

const activityValuesToValueList = (
  activityValues: FlatInteractionModelData['activity_values']
): { uuid_activity_model: string; value: ActivityValue }[] => {
  return Object.entries(activityValues).flatMap(
    ([uuid_activity_model, value]) => {
      if (Array.isArray(value)) {
        return nestedGroupsToValueList(value)
      }

      return { uuid_activity_model, value }
    }
  )
}

const activityValuesToGroupValidationFormat = ({
  activityValues,
  stepUuid
}: ActivityValuesToGroupValidationFormatProps): GroupValidationCallbackRequestData => {
  return {
    uuid_interaction_model_item_group: stepUuid,
    activity_models: activityValuesToValueList(activityValues)
  }
}

function formatInteractionModelCallback(
  interactionModel: FlatInteractionModelData
) {
  const interactionModelItemGroups =
    interactionModel.interaction_model_item_groups.map(({ uuid: stepUuid }) => {
      return activityValuesToGroupValidationFormat({
        activityValues: interactionModel.activity_values,
        stepUuid
      })
    })

  const requestBody = {
    uuid_interaction_model: interactionModel.uuid,
    interaction_model_item_groups: interactionModelItemGroups
  }

  return requestBody
}

function addActivityGroups(
  activityGroups: ActivityModel[][],
  updatedGroups: Activity[][]
) {
  const firstActivityGroup = deepClone(activityGroups[0])

  const unaddedGroups = updatedGroups.slice(activityGroups.length)

  unaddedGroups.forEach(() => {
    activityGroups.push(
      firstActivityGroup.map((activityModel) => {
        delete activityModel.value

        return {
          ...activityModel,
          uuid: activityModel.originalUuid + '-' + activityGroups.length
        }
      })
    )
  })
}

function setActivityGroupValue(activityModel: ActivityGroup, value: string) {
  const updatedGroups: Activity[][] | '' = value && JSON.parse(value)

  if (!updatedGroups) {
    return activityModel
  }

  if (
    activityModel.activity_models.length > 0 &&
    activityModel.activity_models.length < updatedGroups.length
  ) {
    addActivityGroups(activityModel.activity_models, updatedGroups)
  }

  activityModel.activity_models.forEach((group) => {
    updatedGroups.forEach((newGroup) => {
      group.forEach((oldGroupActivityModel) => {
        newGroup.forEach((newGroupActivity) => {
          if (
            oldGroupActivityModel.uuid === newGroupActivity.uuid_activity_model
          ) {
            setActivityModelValue(oldGroupActivityModel, newGroupActivity)
          }
        })
      })
    })
  })
}

const objectSelections: ActivityModelContentType[] = [
  'explicit_uniselection_objectclass',
  'explicit_multiselection_objectclass',
  'standard_multiselection',
  'standard_uniselection',
  'explicit_uniselection_accordion',
  'explicit_multiselection_accordion'
]

function isObjectSelection(contentType: ActivityModelContentType) {
  return objectSelections.includes(contentType)
}

function isContentTypeObject(
  activityModel: Pick<
    ActivityModel,
    'content_type' | 'information_request_filteraction'
  >
) {
  return (
    activityModel?.information_request_filteraction ||
    isObjectSelection(activityModel.content_type)
  )
}

function setActivityModelValue(
  activityModel: ActivityModel,
  activity: Activity
) {
  const isActivityGroup = activityModel.content_type === 'activity_group'

  if (isActivityGroup) {
    setActivityGroupValue(activityModel, activity.des)
    return
  }

  const activityValue = activity.des
    ? { id: activity.des, des: activity.complement || activity.des }
    : ''

  const contentIsObject = isContentTypeObject(activityModel)

  activityModel.value = contentIsObject ? activityValue : activity.des
}

export function setInteractionModelActivities(
  interactionModel: InteractionModelData,
  activities: Activity[]
) {
  const interactionModelClone = deepClone(interactionModel)
  const steps = interactionModelClone.interaction_model_item_groups

  activities.forEach((activity) => {
    steps?.forEach((step) => {
      const newActivityModels: ActivityModel[] = JSON.parse(
        JSON.stringify(step.activity_models)
      )
      newActivityModels.forEach((activityModel) => {
        if (
          activityModel.uuid === activity.uuid_activity_model &&
          activityModel.value !== activity.des
        ) {
          setActivityModelValue(activityModel, activity)
        }
      })
      step.activity_models = newActivityModels
    })
  })

  return {
    ...interactionModelClone,
    interaction_model_item_groups: steps
  }
}

const parseActivityIdValuePair = (
  activity: Activity,
  activityModels: FlatInteractionModelData['activity_models'],
  isFromGroup: boolean = false
) => {
  const uuid = isFromGroup
    ? activity.uuid_activity_model.substring(
        0,
        activity.uuid_activity_model.lastIndexOf('-')
      )
    : activity.uuid_activity_model

  const activityModel = activityModels[uuid]

  const value =
    activity.des && isContentTypeObject(activityModel)
      ? { id: activity.des, des: activity.complement || activity.des }
      : activity.des

  return [uuid, value]
}

export function setFlatInteractionModelActivities(
  flatData: FlatInteractionModelData,
  activities: Activity[]
) {
  activities.forEach((activity) => {
    if (!flatData.activity_models[activity.uuid_activity_model]) {
      return
    }

    if (
      flatData.activity_models[activity.uuid_activity_model].content_type ===
      'activity_group'
    ) {
      const groups: Activity[][] = activity.des ? JSON.parse(activity.des) : []

      flatData.activity_values[activity.uuid_activity_model] = groups.map(
        (activitiesInGroup) =>
          Object.fromEntries(
            activitiesInGroup.map((activityInGroup) =>
              parseActivityIdValuePair(
                activityInGroup,
                flatData.activity_models,
                true
              )
            )
          )
      )

      return
    }

    flatData.activity_values[activity.uuid_activity_model] =
      parseActivityIdValuePair(activity, flatData.activity_models)[1]
  })
}

function getActivityFromActivityGroup(activityModel: ActivityGroup) {
  const groups = activityModel.activity_models

  const groupsActivities = groups.map((group) => {
    const activities = group.map(getActivityFromActivityModel)
    const nonEmptyActivities = activities.filter((activity) => activity.des)
    return nonEmptyActivities
  })

  const nonEmptyGroups = groupsActivities.filter((group) => group.length > 0)

  const activityValue =
    nonEmptyGroups.length > 0 ? JSON.stringify(nonEmptyGroups) : ''

  return {
    uuid_activity_model: activityModel.uuid,
    des: activityValue
  }
}

export function getActivityFromActivityModel(
  activityModel: ActivityModel
): Activity {
  const isActivityGroup = activityModel.content_type === 'activity_group'

  if (isActivityGroup) {
    return getActivityFromActivityGroup(activityModel)
  }

  const contentIsObject = isContentTypeObject(activityModel)

  const activityModelValue = activityModel.value
  const isValueObject = typeof activityModelValue === 'object'

  const uuid = isValueObject ? activityModelValue.id : ''

  const description = isValueObject
    ? activityModelValue.des
    : activityModelValue || ''

  return {
    uuid_activity_model: activityModel.uuid,
    des: contentIsObject ? uuid : description,
    ...(contentIsObject && { complement: description })
  }
}

// Retorna os uuids das activityModels do tipo especificado
export function getUuidsForContentType(
  activityModels: FlatInteractionModelData['activity_models'],
  contentType: ActivityModelContentType
) {
  return Object.entries(activityModels).flatMap(([, activityModel]) =>
    activityModel.content_type === contentType ? activityModel.uuid : []
  )
}

// Troca o conteúdo do campo "des" pelo uuid do documento (document - objectclass)
export function handleDocumentPhoto(
  activities: Activity[],
  activityModels: FlatInteractionModelData['activity_models']
) {
  const uuids = getUuidsForContentType(activityModels, 'document_photo')

  if (uuids.length > 0) {
    uuids.forEach((uuid) => {
      activities.find((activity, index) => {
        if (activity.uuid_activity_model === uuid) {
          const des = JSON.parse(activity.des)
          activities[index] = {
            ...activity,
            des: des.uuid
          }
        }
      })
    })
  }
  return activities
}

export function handleDate(
  activities: Activity[],
  activityModels: FlatInteractionModelData['activity_models'],
  language: string
) {
  const dateUuids = getUuidsForContentType(activityModels, 'date')
  const dateTimeUuids = getUuidsForContentType(activityModels, 'datetime')
  const uuids = [...dateUuids, ...dateTimeUuids]

  if (!uuids.length) {
    return activities
  }

  return activities.map((activity) => {
    if (uuids.includes(activity.uuid_activity_model)) {
      return {
        ...activity,
        des: datepickerToISOFormat(activity.des, language as AllowedLocales)
      }
    }
    return activity
  }) as Activity[]
}

const getSelectedActivityValues = (
  activityValues: FlatInteractionModelData['activity_values'],
  selectedIds: string[]
) => {
  return pick(activityValues, selectedIds)
}

export function formatInteractionPost(
  shownSteps: FlatInteractionModelItemGroup[],
  interactionModel: InteractionModel,
  language: string
) {
  const interactionModelData = interactionModel.flatData

  if (!interactionModelData) {
    return
  }

  const shownActivities = getSelectedActivityValues(
    interactionModelData.activity_values,
    shownSteps.flatMap((step) => step.activity_models)
  )

  const nonEmptyActivities = flatValuesToOldActivities(shownActivities).filter(
    ({ des }) => des
  )

  let activities = nonEmptyActivities

  /*
    Tratamento especial para a activity model "document_photo";
    Deve manipular as activities trocando o conteúdo do campo "des"
    pelo uuid do documento (document - objectclass)
  */
  activities = handleDocumentPhoto(
    activities,
    interactionModelData.activity_models
  )

  activities = handleDate(
    activities,
    interactionModelData.activity_models,
    language
  )

  // coords em string
  const latitude = interactionModel.geolocation?.latitude?.toString() || ''
  const longitude = interactionModel.geolocation?.longitude?.toString() || ''

  const workflowUuid = interactionModel.workflowUuid && {
    uuid_workflow: interactionModel.workflowUuid
  }

  const body = [
    {
      ...workflowUuid,
      uuid_interaction_model: interactionModelData.uuid,
      uuid_user_creation_row: interactionModel.user,
      uuid_user_modification_row: interactionModel.user,
      uuid_language: interactionModel.language,
      latitude: latitude,
      longitude: longitude,
      date_start_interaction: interactionModel.dateStartInteraction,
      date_end_interaction: moment().format(),
      activities
    }
  ]

  return body
}

export async function validateInteractionModelCallback(
  interactionModel: FlatInteractionModelData,
  endpoint: string
) {
  const formatedInteractionModel =
    formatInteractionModelCallback(interactionModel)

  const res = await api.postInputValidation(
    formatedInteractionModel,
    endpoint,
    true
  )
  return res
}

type ValidateInteractionModelItemGroupCallbackProps =
  ActivityValuesToGroupValidationFormatProps & {
    endpoint: string
  }

export async function validateInteractionModelItemGroupCallback({
  activityValues,
  stepUuid,
  endpoint
}: ValidateInteractionModelItemGroupCallbackProps) {
  const formatedInteractionModelItemGroup: GroupValidationCallbackRequestData =
    activityValuesToGroupValidationFormat({ activityValues, stepUuid })

  const res = await api.postInputValidation(
    formatedInteractionModelItemGroup,
    endpoint,
    true
  )
  return res
}

export async function saveInteraction(
  shownSteps: FlatInteractionModelItemGroup[],
  interactionModel: InteractionModel,
  language: string
) {
  const body = formatInteractionPost(shownSteps, interactionModel, language)
  const res = await api.postSaveInteraction(body, true)
  return res
}
