import {
  FlatInteractionModelData,
  FlatInteractionModelItemGroup
} from 'types/shared'
import { useMemo, useRef, useState } from 'react'
import useValidateStep from './useValidateStep/useValidateStep'
import debounce from 'lodash.debounce'

type UseValidationInputProps = {
  steps: FlatInteractionModelItemGroup[]
  activityValues?: FlatInteractionModelData['activity_values']
  activityModels?: FlatInteractionModelData['activity_models']
}

const useValidationInput = ({
  steps,
  activityValues = {},
  activityModels = {}
}: UseValidationInputProps) => {
  const shownStepsActivities = useMemo(
    () => steps.flatMap(({ activity_models }) => activity_models),
    [steps]
  )

  const lastActivityValues = useRef<typeof activityValues>()

  const shouldValidate =
    !lastActivityValues.current ||
    JSON.stringify(lastActivityValues.current) !==
      JSON.stringify(activityValues)

  lastActivityValues.current = activityValues

  return {
    stepActivities: shownStepsActivities,
    activityModels,
    activityValues,
    shouldValidate
  }
}

const DEFAULT_VALIDATION_DEBOUNCE_MS = 500

type UseNavigationProps = UseValidationInputProps & {
  debounceMs?: number
}

const useNavigation = ({
  steps,
  activityValues = {},
  activityModels = {},
  debounceMs = DEFAULT_VALIDATION_DEBOUNCE_MS
}: UseNavigationProps) => {
  const { stepActivities, shouldValidate } = useValidationInput({
    steps,
    activityModels,
    activityValues
  })

  const { validateStep } = useValidateStep()

  const lastValidationPromise = useRef<ReturnType<typeof validateStep>>()

  const [validatedInteraction, setValidatedInteraction] = useState(false)

  const validateDebounced = debounce(() => {
    const currentValidationPromise = validateStep({
      stepActivities,
      activityModels,
      activityValues
    })

    lastValidationPromise.current = currentValidationPromise

    currentValidationPromise.then(({ invalidActivities }) => {
      if (lastValidationPromise.current !== currentValidationPromise) {
        return
      }

      setValidatedInteraction(invalidActivities.length === 0)
    })
  }, debounceMs)

  const cancelLastDebouncedValidation = useRef(() => {})

  if (shouldValidate) {
    setValidatedInteraction(false)

    cancelLastDebouncedValidation.current()

    validateDebounced()
    cancelLastDebouncedValidation.current = () => validateDebounced.cancel()
  }

  return {
    showDoubleArrowhead: validatedInteraction
  }
}

export default useNavigation
