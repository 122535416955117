import { useMemo } from 'react'
import { FlatInteractionModelData, SequenceDecisionModel } from 'types/shared'

type UseSequenceRulesProps = {
  activityModels: FlatInteractionModelData['activity_models']
  activityValues: FlatInteractionModelData['activity_values']
}

export default function useSequenceRules({
  activityModels,
  activityValues
}: UseSequenceRulesProps) {
  const sequenceDecisionModels = useMemo(() => {
    return Object.entries(activityModels || {}).reduce<
      Record<string, SequenceDecisionModel>
    >((result, [uuid, currentModel]) => {
      if (currentModel.sequence_decision_model) {
        result[uuid] = currentModel.sequence_decision_model
      }

      return result
    }, {})
  }, [activityModels])

  return useMemo<{
    hiddenStepIds: string[]
    hasPendingDecisionModels: boolean
  }>(() => {
    const hiddenStepsSet = new Set<string>()
    const shownStepsSet = new Set<string>()

    const hasSequenceDecisionModels =
      Object.keys(sequenceDecisionModels).length > 0

    if (!activityValues || !hasSequenceDecisionModels) {
      return {
        hiddenStepIds: [],
        hasPendingDecisionModels: hasSequenceDecisionModels
      }
    }

    let hasPendingDecisionModels = false

    Object.entries(sequenceDecisionModels).forEach(
      ([uuid, sequenceDecisionModel]) => {
        const value = activityValues[uuid]

        if (!value || Array.isArray(value)) {
          hasPendingDecisionModels = true

          return
        }

        const selections =
          typeof value === 'string'
            ? [value]
            : (value.id || value.des).split(',')

        const actions = selections.flatMap((selection) => {
          return sequenceDecisionModel[selection] || []
        })

        actions.forEach((action) => {
          if (action.action === 'show_step') {
            action.step_uuids.forEach((stepUuid) => shownStepsSet.add(stepUuid))
          }

          if (action.action === 'hide_step') {
            action.step_uuids.forEach((stepUuid) =>
              hiddenStepsSet.add(stepUuid)
            )
          }
        })
      }
    )

    shownStepsSet.forEach((shownStepId) => hiddenStepsSet.delete(shownStepId))

    return {
      hiddenStepIds: Array.from(hiddenStepsSet),
      hasPendingDecisionModels
    }
  }, [activityValues, sequenceDecisionModels])
}
