import { createContext, useContext } from 'react'

type BlueprintsContextType = {
  isFetchingBlueprints?: boolean
  executeBlueprints: () => void
}

export const BlueprintsContext = createContext<BlueprintsContextType>({
  isFetchingBlueprints: false,
  executeBlueprints: () => {}
})

export default function useBlueprintsContext() {
  return useContext(BlueprintsContext)
}
