import { interactionModelActions } from './slice'

export const {
  addActivityGroup,
  removeActivityGroup,
  updateActivities,
  updateItemGroups,
  setGeolocation,
  getInteractionModel,
  setConfigToNewInteraction,
  setInitialConfiguration,
  setWorkflow
} = interactionModelActions

export default interactionModelActions
