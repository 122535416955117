import {
  UseQueryOptions,
  useQueries,
  useQueryClient
} from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import {
  ActivityValue,
  FlatActivityModel,
  ValidationStatus
} from 'types/shared'

import { validateFlatActivityValue } from 'utils/validateActivityValue'

type ValidableActivity = {
  activityModel: FlatActivityModel
  value?: ActivityValue
}

const getValidateActivityQueryOptions = ({
  activityModel,
  value
}: ValidableActivity) =>
  ({
    queryKey: ['validateFlatActivityValue', activityModel.uuid, value],
    queryFn: () => validateFlatActivityValue({ activityModel, value }),
    initialData: {
      validationStatus: 'pending' as ValidationStatus,
      helperText: ''
    }
  }) satisfies UseQueryOptions

export const useImperativeValidateActivities = () => {
  const queryClient = useQueryClient()

  const validateActivities = useCallback(
    async (activities: ValidableActivity[]) =>
      Promise.all(
        activities.map((activity) =>
          queryClient.fetchQuery(getValidateActivityQueryOptions(activity))
        )
      ),
    [queryClient]
  )

  return validateActivities
}

type UseValidateActivitiesProps = {
  activities: ValidableActivity[]

  enabled?: boolean
}

export const useValidateActivities = ({
  activities,
  enabled
}: UseValidateActivitiesProps) => {
  const results =
    useQueries({
      queries: activities.map(
        (activity) =>
          ({
            ...getValidateActivityQueryOptions(activity),
            enabled
          }) satisfies UseQueryOptions
      )
    }) || []

  type Result = (typeof results)[number]

  type ResultWithInitialData = Omit<Result, 'data'> & {
    data: NonNullable<Result['data']>
  }

  return results as ResultWithInitialData[]
}

type UseValidateActivityProps = {
  activityModel: FlatActivityModel
  value?: ActivityValue
  enabled?: boolean
  debounceTime?: number
}

export default function useValidateActivity({
  activityModel,
  value,
  enabled,
  debounceTime = 300
}: UseValidateActivityProps) {
  const [activities, setActivities] = useState([{ activityModel, value }])

  const debounceActivityValue = () => {
    const timeout = setTimeout(
      () => setActivities([{ activityModel, value }]),
      debounceTime
    )

    return () => clearTimeout(timeout)
  }

  useEffect(debounceActivityValue, [activityModel, value, debounceTime])

  const [{ data, ...queryInfo }] = useValidateActivities({
    activities,
    enabled
  })

  return {
    ...data,
    ...queryInfo
  }
}
