import {
  ActivityValue as ActivityValueType,
  NestableGroupValue
} from 'types/shared'
import ActivityDocumentPhoto from './ActivityDocumentPhoto'
import ActivityValue from './ActivityValue'
import ActivityDocumentFile from './ActivityDocumentFile'
import ActivitySafeHTML from '../../ActivityInput/ActivitySafeHTML/ActivitySafeHTML'
import { useAppSelector } from 'state/store'
import { getProperty } from 'utils/helpers/object'

interface ActivityProps {
  activityModelIds: string[]
  parentPath?: string[]
}

const Activity = ({ activityModelIds, parentPath = [] }: ActivityProps) => {
  const flatData = useAppSelector(
    (state) => state.interactions.interactionModel.flatData
  )
  const activityModels = flatData?.activity_models || {}
  const activityValues = flatData?.activity_values || {}

  return (
    <>
      {activityModelIds?.map((activityModelId) => {
        const activityModel = activityModels[activityModelId]

        const isActivityGroup = activityModel.content_type === 'activity_group'
        const isDocumentActivity =
          activityModel.content_type === 'document_photo' ||
          activityModel.content_type === 'document_file'

        const activityValue = getProperty<
          ActivityValueType | NestableGroupValue
        >(activityValues, [...parentPath, activityModelId])

        return (
          <div key={activityModel.uuid}>
            {activityModel.content_type === 'safe_html' && (
              <ActivitySafeHTML htmlContent={activityModel.html_content} />
            )}

            {activityValue && (
              <>
                {activityModel.content_type === 'document_photo' && (
                  <ActivityDocumentPhoto values={activityValue as string} />
                )}

                {activityModel.content_type === 'document_file' && (
                  <ActivityDocumentFile
                    values={activityValue as string}
                    label={activityModel.display_text.des}
                  />
                )}

                {!isDocumentActivity && !Array.isArray(activityValue) && (
                  <ActivityValue
                    uuid={activityModel.uuid}
                    label={activityModel.display_text.des}
                    value={activityValue}
                    contentType={activityModel.content_type}
                    required={activityModel.required}
                  />
                )}
              </>
            )}

            {isActivityGroup &&
              Array.isArray(activityValue) &&
              activityValue.map((activityGroup, index) => {
                return (
                  <div key={index} className='mb-2'>
                    <div className='card-body'>
                      <h2>{activityModel.display_text.des}</h2>
                      <Activity
                        activityModelIds={Object.keys(activityGroup)}
                        parentPath={[
                          ...parentPath,
                          activityModelId,
                          index.toString()
                        ]}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        )
      })}
    </>
  )
}

export default Activity
