import { useQuery } from '@tanstack/react-query'

import { Translate } from 'types/shared'
import Workflow from 'types/workflow'

import apiAsync from 'utils/api-async'

type FetchWorkflowResponse = {
  data?: {
    workflow: Workflow
  }
  success: boolean
  message: string
}

export const fetchWorkflow = (
  workflowUuid: string | null,
  t: Translate
): Promise<FetchWorkflowResponse> => {
  if (!workflowUuid) {
    return Promise.resolve({
      success: false,
      message: t('workflow.not-found')
    })
  }

  return apiAsync.requestSync({
    endpoint: 'workflows',
    path: workflowUuid,
    method: 'GET',
    showLoading: true
  })
}

export default function useWorkflow({
  workflowUuid,
  t
}: {
  workflowUuid: string | null
  t: Translate
}) {
  const { data: response, isFetching } = useQuery<FetchWorkflowResponse>({
    queryKey: ['workflow', workflowUuid],
    queryFn: () => fetchWorkflow(workflowUuid, t)
  })

  return {
    workflow: response?.data?.workflow,
    isFetching
  }
}
