import { datepickerToISOFormat } from '@yes.technology/react-toolkit'
import {
  Activity,
  ActivityModelContentType,
  FlatInteractionModelData
} from 'types/shared'
import { AllowedLocales } from './date'

type Formatter = (activity: Activity, language: string) => Activity

const defaultFormatter: Formatter = (activity) => activity

const dateFormatter: Formatter = (activity: Activity, language) => {
  return {
    ...activity,
    des:
      datepickerToISOFormat(activity.des, language as AllowedLocales) ||
      activity.des
  }
}

type Formatters = {
  [key in ActivityModelContentType]?: Formatter
}

const formatters: Formatters = {
  date: dateFormatter,
  datetime: dateFormatter
}

export default function formatBlueprintsRequestActivities(
  activities: Activity[],
  activityModels: FlatInteractionModelData['activity_models'],
  language: string
): Activity[] {
  return activities.map((activity) => {
    const activityModel = activityModels[activity.uuid_activity_model]

    if (!activityModel) {
      return activity
    }

    const formatter = formatters[activityModel.content_type] || defaultFormatter

    return formatter(activity, language)
  })
}
