import { ExplicitSelectionAccordion } from '@yes.technology/react-toolkit'
import useFetchSuggestions from 'modules/interaction/hooks/useFetchSuggestions'
import { useEffect } from 'react'
import { ActivityModel } from 'types/shared'
import { FilteractionParams } from 'utils/apiTypes'
import { ActivityInputProps } from '../Activity.types'

export type ActivityExplicitUniselectionAccordionProps = {
  id: string
  filteraction?: string
  filteractionParams?: FilteractionParams[]
  value: ActivityModel['value']
  onChange: ActivityInputProps['handleChange']
} & Pick<
  React.ComponentProps<typeof ExplicitSelectionAccordion>,
  'hideAccordionArrowOnDisable' | 'options' | 'required'
>

const ActivityExplicitUniselectionAccordion = ({
  id,
  filteraction,
  value,
  options = [],
  onChange,
  hideAccordionArrowOnDisable,
  required
}: ActivityExplicitUniselectionAccordionProps) => {
  const { suggestions, fetchSuggestions } = useFetchSuggestions({
    id,
    filteraction,
    showLoading: true,
    projectFields: { uuid: 1, des: 1, complement: 1 }
  })

  useEffect(() => {
    if (!filteraction) {
      return
    }

    fetchSuggestions('.*')
  }, [fetchSuggestions, filteraction])

  const finalOptions = options.length ? options : suggestions
  const checkedOption =
    typeof value === 'string'
      ? value
      : finalOptions.find(
          (option) => typeof value === 'object' && option.uuid === value?.id
        )?.uuid

  const handleChange = ({ des, uuid }: { des: string; uuid: string }) => {
    const checkedUuid = checkedOption === uuid ? '' : uuid

    onChange({ id: checkedUuid, des })
  }

  return (
    <ExplicitSelectionAccordion
      variant='uniselection'
      options={finalOptions}
      optionLabelsPosition={'right'}
      id={id}
      checkedOption={checkedOption}
      required={required}
      onChange={handleChange}
      hideAccordionArrowOnDisable={hideAccordionArrowOnDisable}
      textContentVariant='bordertext-04'
    />
  )
}

export default ActivityExplicitUniselectionAccordion
