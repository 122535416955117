import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Response } from 'cross-fetch'
import debounce from 'lodash.debounce'

import { ProjectFields, YesObject } from '@yes.technology/react-toolkit'

import { useAppDispatch, useAppSelector } from 'state/store'

import { InteractionModelItemGroup } from 'types/shared'
import api from 'utils/api'
import { FilteractionParams } from 'utils/apiTypes'

type AutocompleteResponse = {
  data:
    | YesObject[]
    | {
        objects: YesObject[]
      }
  message: string
  success: boolean
}

type UseFetchSuggestionsProps = {
  id: string
  filteractionParams?: object[]
  filteraction?: string
  endpoint?: string
  showLoading?: boolean
  projectFields?: ProjectFields
}

type FetchSuggestionsArgsProps = UseFetchSuggestionsProps & {
  interactionModelItemGroupsState: InteractionModelItemGroup[] | undefined
}

export default function useFetchSuggestions({
  id,
  filteractionParams,
  filteraction,
  endpoint,
  showLoading = false,
  projectFields
}: UseFetchSuggestionsProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<YesObject[]>([])

  const ignoreResponse = useRef(false)

  const fetchSuggestionsSyncedArgs = useRef<FetchSuggestionsArgsProps>({
    id,
    filteractionParams,
    filteraction,
    endpoint,
    showLoading,
    interactionModelItemGroupsState: []
  })

  const dispatch = useAppDispatch()

  const resetSuggestions = () => {
    debouncedFetchSuggestions.cancel()
    ignoreResponse.current = true

    setIsLoading(false)
    setSuggestions([])
  }

  const { interactionModel } = useAppSelector(
    ({ interactions }) => interactions
  )

  useEffect(() => {
    const interactionModelItemGroupsState =
      interactionModel?.data?.interaction_model_item_groups

    fetchSuggestionsSyncedArgs.current = {
      id,
      filteractionParams,
      filteraction,
      endpoint,
      showLoading,
      interactionModelItemGroupsState
    }
  }, [
    endpoint,
    showLoading,
    filteraction,
    filteractionParams,
    interactionModel?.data?.interaction_model_item_groups,
    id
  ])

  const fetchSuggestions = useCallback(
    async (value: string) => {
      ignoreResponse.current = false

      const {
        id,
        filteractionParams,
        filteraction,
        endpoint,
        showLoading,
        interactionModelItemGroupsState
      } = fetchSuggestionsSyncedArgs.current

      try {
        setIsLoading(true)

        const throwError = false

        const response: AutocompleteResponse = await api.getAutocomplete(
          value,
          endpoint || '',
          !!showLoading,
          filteraction,
          filteractionParams as FilteractionParams[],
          interactionModelItemGroupsState || [],
          id,
          throwError,
          projectFields
        )

        if (response?.success === true && !ignoreResponse.current) {
          let results = response.data

          if ('objects' in response.data) {
            results = response.data.objects
          }

          if (!Array.isArray(results)) {
            results = []
          }

          setSuggestions(results)
        } else {
          if (!ignoreResponse.current) {
            throw new Error(response?.message || 'Response vazia ou com erro')
          }
        }
      } catch (error) {
        if (!ignoreResponse.current) {
          if (error instanceof Response) {
            dispatch({ type: 'api/ERROR', data: { status: error.status } })
          }

          console.error(error)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, projectFields]
  )

  const debouncedFetchSuggestions = useMemo(
    () => debounce(fetchSuggestions, 350),
    [fetchSuggestions]
  )

  return {
    suggestions,
    setSuggestions,
    isLoading,
    fetchSuggestions,
    debouncedFetchSuggestions,
    resetSuggestions
  }
}
