import { ActivityModel, ValidationStatus } from 'types/shared'
import api from 'utils/api'

export const validateUsingCallback = async ({
  validation_activity_callback: validationCallback,
  value
}: Pick<ActivityModel, 'validation_activity_callback' | 'value'>): Promise<{
  validationStatus: ValidationStatus
  helperText: string
}> => {
  if (!validationCallback || value === '') {
    return { validationStatus: 'pending', helperText: '' }
  }

  const reqObj = {
    content: value
  }

  const onError = (e: Error) => ({ success: false, message: e.message })

  const { success, message = '' } = await api
    .postInputValidation(reqObj, validationCallback, false)
    .catch(onError)
  const validMessage = 'activity-input.validation.is_valid'

  return {
    validationStatus: success ? 'valid' : 'invalid',
    helperText: success ? validMessage : message
  }
}
