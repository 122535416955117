import { useAppSelector } from 'state/store'

const useInteraction = () => {
  const {
    interactionModel: { user, dateStartInteraction, geolocation }
  } = useAppSelector(({ interactions }) => interactions)

  return {
    user,
    dateStartInteraction,
    geolocation
  }
}

export default useInteraction
