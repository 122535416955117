import { FlatInteractionModelData, GeolocationDependencies } from 'types/shared'
import { getProperty } from 'utils/helpers/object'

const getValueActivityModel = (
  value?: FlatInteractionModelData['activity_values']['']
): string | undefined => {
  if (typeof value === 'string') {
    return value
  }

  if (typeof value === 'object' && 'des' in value) {
    return value.des
  }
}

const getValueByDependency = (
  activitiesByUuid: FlatInteractionModelData['activity_values'],
  dependency: string,
  parentPath: string[]
) => {
  return getValueActivityModel(
    getProperty(activitiesByUuid, [...parentPath, dependency])
  )
}

type UseAddressFromModelProps = {
  activitiesByUuid: FlatInteractionModelData['activity_values']
  dependencies: GeolocationDependencies
  parentPath?: string[]
}

export default function useAddressFromModel({
  activitiesByUuid,
  dependencies,
  parentPath = []
}: UseAddressFromModelProps) {
  const state = getValueByDependency(
    activitiesByUuid,
    dependencies.state,
    parentPath
  )
  const city = getValueByDependency(
    activitiesByUuid,
    dependencies.city,
    parentPath
  )
  const street = getValueByDependency(
    activitiesByUuid,
    dependencies.street,
    parentPath
  )
  const number = getValueByDependency(
    activitiesByUuid,
    dependencies.number,
    parentPath
  )
  const neighborhood = getValueByDependency(
    activitiesByUuid,
    dependencies.neighborhood,
    parentPath
  )
  const zipCode = getValueByDependency(
    activitiesByUuid,
    dependencies.zip_code,
    parentPath
  )
  const filledAddressFields = [
    street,
    number,
    neighborhood,
    city,
    state,
    zipCode
  ].filter((v): v is string => Boolean(v))

  const requiredAddressFields = [street, city, state]
  const isAddressFilled = requiredAddressFields.every(Boolean)
  const address = isAddressFilled ? filledAddressFields.join(', ') : ''

  return address
}
