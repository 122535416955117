import { useCallback, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18n-lite'

import { NavigationSlider, Button, Icon } from '@yes.technology/react-toolkit'

import { useBlueprintsContext } from 'modules/interaction/hooks'
import useValidateStep from 'modules/interaction/hooks/useValidateStep/useValidateStep'
import { flatValuesToOldActivities } from 'modules/interaction/utils/flatValuesToOldActivities'

import { useAppDispatch, useAppSelector } from 'state/store'

import { deepClone, getProperty } from '../../../../utils/helpers/object'

import { interactionModelOperations } from '../../redux'
import { getStepErrorMessages, showError } from '../../utils/validation'

import Activity from './Activity'

import {
  FlatActivityGroup as ActivityGroupType,
  ActivityValue,
  FlatInteractionModelData,
  NestableGroupValue
} from 'types/shared'

const getGroupValues = (
  path: string[],
  activityValues: FlatInteractionModelData['activity_values'] = {}
): NestableGroupValue => {
  const values = getProperty(activityValues, path)

  return Array.isArray(values) ? values : [{}]
}

export type ActivityGroupProps = {
  currentActivityModel: ActivityGroupType
  parentPath: string[]
  onChange: (activities: string) => void
  label: string
  currentStep: number
}

const ActivityGroup = ({
  currentActivityModel,
  parentPath,
  onChange,
  label,
  currentStep
}: ActivityGroupProps) => {
  const { interactionModel } = useAppSelector(
    ({ interactions }) => interactions
  )

  const values = getGroupValues(
    [...parentPath, currentActivityModel.uuid],
    interactionModel.flatData?.activity_values
  )

  const totalElements = values.length || 1

  const [currentPosition, setCurrentPosition] = useState(totalElements)

  const currentValues = values[Math.min(totalElements, currentPosition) - 1]

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { validateStep } = useValidateStep()

  const handleAddGroup = async () => {
    const flatData = interactionModel.flatData

    if (!flatData) {
      return
    }

    const currentStepData =
      flatData.interaction_model_item_groups[currentStep - 1]

    const activityModels = flatData.activity_models

    const activityValues = flatData.activity_values

    if (!currentStepData) {
      return
    }

    const { invalidActivities, updatedActivityValues } = await validateStep({
      stepActivities: currentStepData.activity_models,
      activityModels,
      activityValues
    })

    if (invalidActivities.length) {
      dispatch(
        interactionModelOperations.updateActivities(
          flatValuesToOldActivities(updatedActivityValues)
        )
      )

      getStepErrorMessages({
        invalidActivities,
        stepName: currentStepData.des,
        t
      }).forEach((error) => showError(error))

      return
    }

    dispatch(
      interactionModelOperations.addActivityGroup(
        currentActivityModel.uuid,
        parentPath
      )
    )
  }

  const handleRemoveGroup = (removedGroupIndex: number) => {
    dispatch(
      interactionModelOperations.removeActivityGroup(
        currentActivityModel.uuid,
        parentPath,
        removedGroupIndex
      )
    )
  }

  useEffect(() => {
    setCurrentPosition(totalElements)
  }, [totalElements])

  const currentGroupPath = useMemo(
    () => [
      ...parentPath,
      currentActivityModel.uuid,
      (currentPosition - 1).toString()
    ],
    [currentActivityModel.uuid, currentPosition, parentPath]
  )

  const changeActivity = useCallback(
    (uuid: string, value: ActivityValue) => {
      const [farthestParentId] = currentGroupPath
      const mainGroupValues = deepClone(
        (interactionModel.flatData?.activity_values[
          farthestParentId
        ] as NestableGroupValue) || []
      )

      if (!Array.isArray(mainGroupValues) || !mainGroupValues.length) {
        return
      }

      const updatedValues = {
        [farthestParentId]: mainGroupValues
      }

      const currentGroupPositionValue = getGroupValues(
        currentGroupPath.slice(0, -1),
        updatedValues
      )[currentPosition - 1]

      if (!currentGroupPositionValue) {
        return
      }

      currentGroupPositionValue[uuid] = value

      onChange(flatValuesToOldActivities(updatedValues)[0].des)
    },
    [
      currentGroupPath,
      currentPosition,
      interactionModel.flatData?.activity_values,
      onChange
    ]
  )

  const { isFetchingBlueprints } = useBlueprintsContext()

  const disableAdd = isFetchingBlueprints

  const disableRemove = isFetchingBlueprints || totalElements === 1

  return (
    <>
      <NavigationSlider
        currentPosition={currentPosition}
        first={1}
        total={totalElements}
        hierarchyLevel='level_3'
        doubleArrowhead={true}
        textForCurrent={t('activity-group.navigation-slider.text-for-current')}
        afterClick={setCurrentPosition}
        heightClass='xSmall'
      />
      <div>
        <div className='d-flex justify-content-between mt-2'>
          <h2>{label}</h2>
          <div className='d-flex'>
            <Button
              impactClass='navigation'
              onClick={handleAddGroup}
              heightClass='small'
              style={{
                width: '34px',
                marginLeft: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              disabled={disableAdd}
            >
              <Icon iconName='Plus' icSize='small' />
            </Button>
            <Button
              impactClass='navigation'
              variant='secondary'
              onClick={() => handleRemoveGroup(currentPosition - 1)}
              heightClass='small'
              disabled={disableRemove}
              style={{
                width: '34px',
                marginLeft: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Icon iconName='Cleaner' icSize='small' />
            </Button>
          </div>
        </div>
      </div>

      <div>
        {currentActivityModel.activity_models.flatMap((uuid) => {
          const activityModelInput =
            interactionModel.flatData?.activity_models[uuid]

          if (!activityModelInput) {
            return []
          }

          const value = currentValues[uuid]

          return (
            <Activity
              key={uuid}
              activityModelInput={activityModelInput}
              value={Array.isArray(value) ? undefined : value}
              parentPath={currentGroupPath}
              changeActivity={changeActivity}
              currentStep={currentStep}
            />
          )
        })}
      </div>
    </>
  )
}

export default ActivityGroup
