import { useTranslation } from 'react-i18n-lite'
// import CheckIcon from '../../../asset/img/loading-balls-circle-blue.svg'
import success from '../../../asset/img/success.svg'
import {
  DocumentListCard,
  isEmbedded,
  SafeHtmlContent,
  Text
} from '@yes.technology/react-toolkit'
import { useEffect, useState } from 'react'
import { FlatInteractionModelData } from 'types/shared'

export const GET_SHARED_STATE_MESSAGE_TYPE = 'getSharedState'
export const PATCH_SHARED_STATE_MESSAGE_TYPE = 'patchSharedState'

const Success = ({
  interactionModel
}: {
  interactionModel?: FlatInteractionModelData
}) => {
  const { t } = useTranslation()

  const [logoSrc, setLogoSrc] = useState<string>()
  const [applicationOwner, setApplicationOwner] = useState<string>()

  useEffect(() => {
    if (!isEmbedded()) return

    window.parent.postMessage({ type: GET_SHARED_STATE_MESSAGE_TYPE }, '*')

    const messageHandler = ({ data }: MessageEvent) => {
      const { type, sharedState } = data

      if (type === PATCH_SHARED_STATE_MESSAGE_TYPE) {
        setLogoSrc(sharedState.MainAppLogo)
        setApplicationOwner(sharedState.owner)
      }
    }

    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [])

  const { success_message } = interactionModel || {}

  return (
    <>
      <div className='pt-3'>
        <DocumentListCard
          spacingClass='doubleXLarge'
          className='container d-flex flex-column align-items-center gap-4'
        >
          {logoSrc && (
            <img
              src={logoSrc}
              height='32px'
              alt={
                applicationOwner
                  ? t('step.success.logo-alt', { owner: applicationOwner })
                  : t('step.success.logo-alt-fixed')
              }
            />
          )}
          <img src={success} alt='icon success' />

          <Text as='h1' variant='content-emphasis-06' className='text-center'>
            {success_message?.des ?? t('step.success.des')}
          </Text>

          {success_message?.complement && (
            <span className='text-center'>
              <SafeHtmlContent html={success_message.complement} />
            </span>
          )}
        </DocumentListCard>
      </div>
    </>
  )
}

export default Success
