import {
  ActivityValue,
  FlatActivityGroup,
  FlatActivityModel,
  FlatInteractionModelData,
  FlatInteractionModelItemGroup,
  NestableGroupValue
} from 'types/shared'
import { useImperativeValidateActivities } from '../useValidateActivity/useValidateActivity'

type ShouldValidateGroupProps = {
  group: FlatActivityGroup
  values: NestableGroupValue
  activityModels: FlatInteractionModelData['activity_models']
}

const shouldValidateGroup = ({
  group,
  values,
  activityModels
}: ShouldValidateGroupProps) => {
  if (group.required) {
    return true
  }

  const hasFieldValuesOrRequiredGroups = group.activity_models.some(
    (activityInGroupUuid) => {
      const activityModel = activityModels[activityInGroupUuid]

      return values.some((groupValue) => {
        const value = groupValue[activityInGroupUuid]

        if (!Array.isArray(value)) {
          return Boolean(value)
        }

        return (
          activityModel.content_type === 'activity_group' &&
          shouldValidateGroup({
            group: activityModel,
            values: value,
            activityModels
          })
        )
      })
    }
  )

  return hasFieldValuesOrRequiredGroups
}

type GetActivitiesAndUpdatedValuesProps = {
  activityModelUuid: string
  activityModels: FlatInteractionModelData['activity_models']
  activityValues: FlatInteractionModelData['activity_values']
  updatedActivityValues: FlatInteractionModelData['activity_values']
  parentPath?: string[]
  ancestorRequiresValidation?: boolean
}

const getValidableActivities = ({
  activityModelUuid,
  activityModels,
  activityValues,
  updatedActivityValues,
  parentPath,
  ancestorRequiresValidation
}: GetActivitiesAndUpdatedValuesProps): {
  activityModel: FlatActivityModel
  value: ActivityValue
  parentPath?: string[]
}[] => {
  const activityModel = activityModels[activityModelUuid]
  const value =
    activityValues[activityModelUuid] || !activityModel.required
      ? activityValues[activityModelUuid]
      : ''

  if (value !== undefined) {
    updatedActivityValues[activityModelUuid] = JSON.parse(JSON.stringify(value))
  }

  if (Array.isArray(value)) {
    if (activityModel.content_type !== 'activity_group') {
      return []
    }

    const currentGroupRequiresValidation =
      ancestorRequiresValidation ||
      (!parentPath &&
        shouldValidateGroup({
          activityModels,
          group: activityModel,
          values: value
        }))

    return currentGroupRequiresValidation
      ? value.flatMap((group, groupIndex) =>
          activityModel.activity_models.flatMap((activityInGroupUuid) =>
            getValidableActivities({
              activityModelUuid: activityInGroupUuid,
              activityModels,
              activityValues: group,
              updatedActivityValues: (
                updatedActivityValues[activityModelUuid] as NestableGroupValue
              )[groupIndex],
              parentPath: [
                ...(parentPath || []),
                activityModel.uuid,
                groupIndex.toString()
              ],
              ancestorRequiresValidation: true
            })
          )
        )
      : []
  }

  return [{ activityModel, value, ...(parentPath ? { parentPath } : {}) }]
}

type ValidateStepProps = {
  activityModels: FlatInteractionModelData['activity_models']
  activityValues: FlatInteractionModelData['activity_values']
  stepActivities: FlatInteractionModelItemGroup['activity_models']
}

export default function useValidateStep() {
  const validateActivities = useImperativeValidateActivities()

  const validateStep = async ({
    activityModels,
    activityValues,
    stepActivities
  }: ValidateStepProps) => {
    const updatedActivityValues: typeof activityValues = {}

    const activities = stepActivities.flatMap((activityModelUuid) =>
      getValidableActivities({
        activityModelUuid,
        activityModels,
        activityValues,
        updatedActivityValues
      })
    )

    const validations = await validateActivities(activities)

    const invalidActivities = validations.flatMap((validation, index) => {
      return validation.validationStatus === 'invalid'
        ? {
            ...activities[index],
            helperText: validation.helperText
          }
        : []
    })

    return {
      validations,
      invalidActivities,
      activities,
      updatedActivityValues
    }
  }

  return { validateStep }
}
